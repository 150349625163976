@import "style/utilities/colors";
@import "style/utilities/variables";

.header_menu_item {
  &__list_item {
    list-style-type: none;
    display: flex;
    align-items: center;
  }

  &__link {
    padding: 24px 16px 0 16px;
    margin: 0 8px;
    border-bottom: 2px solid transparent;
    opacity: 0.4;
    color: $fgDefault;
    font-size: $fsLead;
    text-decoration: none;
    display: flex;
    align-items: center;

    &:hover {
      text-decoration: none;
      color: $fgDefault;
      opacity: 1;
    }

    &--active {
      border-bottom: 2px solid $fgDefault;
      opacity: 1;
    }
  }

  &__label {
    padding-left: 8px;
  }
}
