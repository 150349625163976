@import "style/utilities/colors";
@import "style/utilities/variables";

.pool-manager {
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  &__desc {
    width: 200px;
    color: #868e9f;
    font-size: $fsXSmall;
    padding-right: 32px;
  }

  &__num_pools {
    color: $fgDefault;
    font-size: $fsXSmall;
    padding-right: 32px;
  }

  &__add_button {
    &--selected {
      background: #3B4351;
    }
  }

  &__select_container {
    position: relative;
    width: fit-content;
  }
}
