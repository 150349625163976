@import "style/utilities/colors";
@import "style/utilities/variables";

.change__password {
  display: flex;
  flex-direction: column;
  max-width: 380px;

  >* {
    padding: 8px;
  }

  &__error {
    color: $errorDefault;
    text-align: center;
    text-transform: none;
    font-size: $fsSmall;
  }

  &__input {
    position: relative;
    display: flex;
    flex-grow: 1;

    >input {
      width: 100%;
    }
  }

  &__showbtn {
    background: transparent;
    cursor: pointer;
    border: 0;
    position: absolute;
    right: 16px;
    height: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
