@import "style/utilities/colors";
@import "style/utilities/variables";

.input {
  &__label {
    color: $fgDefault;
    font-size: $fsXSmall;
    margin-bottom: 4px;
  }

  &__field {
    margin-top: 4px;
    background: $black;
    border: 1px solid #868e9f;
    border-radius: 4px;
    padding: 12px 16px;
    color: $fgDefault;
  }
}
