.header {
  background: #151718;

  .header__actions {
    display: flex;
    align-items: center;

    // Ensure the notifications dropdown appears to the left
    .header__notifications {
      margin-right: auto; // pushes the notifications to the left
    }

    // Optional: if you want to control the spacing between the two dropdowns
    .header__profile {
      margin-left: 20px; // adjust as needed
    }
  }

  &__nav {
    display: grid;
    grid-template-columns: 1fr 3fr 2fr;
    grid-template-rows: auto;
    grid-template-areas:
      "header-logo       header-main header-main header-main header-actions header-actions"
      "header-sub-spacer header-sub  header-sub  header-sub  header-sub     header-sub";
  }

  &__logo {
    margin-left: 20px;
    grid-area: header-logo;
    justify-self: left;
    align-self: center;
  }

  &__logo_img {
    height: 20px;
  }

  &__menu_items {
    height: 100%;
    grid-area: header-main;
    display: flex;
    align-items: end;
  }

  &__menu_items_list {
    display: flex;
    margin: 0;
    padding: 0;
  }

  &__nav_sub {
    background: #212122;
    min-height: 50px;
    grid-area: header-sub;
    display: flex;
  }

  &__nav_sub_spacer {
    background: #212122;
    grid-area: header-sub-spacer;
  }

  &__actions {
    grid-area: header-actions;
    justify-self: right;
    align-self: center;
    margin-right: 20px;
  }
}
