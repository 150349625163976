@tailwind base;
@tailwind components;
@tailwind utilities;

input[type='number'] {
  -moz-appearance: textfield;
}

html,
body,
#root {
  overflow: hidden;
}

@keyframes shimmer {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.animate-shimmer {
  animation: shimmer 2s infinite;
}

@layer components {
  .shadow-combined {
    box-shadow: 0px 0px 1px 0px #0D0D0D, 0px 16px 24px 0px #0D0D0DE5;
  }
}
