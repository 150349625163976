$primaryFontFamily: 'Heebo';
$secondaryFontFamily: 'Assistant';
$dataFontFamily: 'PT Mono';

$normalWeight: 400;
$primaryBoldWeight: 500;
$secondaryBoldWeight: 700;

$primaryXTiny: $normalWeight 7px/1.67 $primaryFontFamily;
$primaryTiny: $normalWeight 10px/1.67 $primaryFontFamily;
$primaryXSmall: $normalWeight 12px/1.67 $primaryFontFamily;
$primaryXSmallBold: $primaryBoldWeight 12px/1.67 $primaryFontFamily;
$primarySmall: $normalWeight 14px/1.43 $primaryFontFamily;
$primaryBody: $normalWeight 16px/1.5 $primaryFontFamily;
$primaryLead: $normalWeight 18px/1.56 $primaryFontFamily;
$primaryHeadline6: $normalWeight 20px/1.4 $primaryFontFamily;
$primaryHeadline5: $normalWeight 24px/1.33 $primaryFontFamily;
$primaryHeadline4: $normalWeight 28px/1.29 $primaryFontFamily;
$primaryHeadline3: $normalWeight 32px/1.25 $primaryFontFamily;
$primaryHeadline2: $normalWeight 36px/1.22 $primaryFontFamily;
$primaryHeadline1: $normalWeight 40px/1.30 $primaryFontFamily;

$secondaryXSmall: $normalWeight 12px/1.67 $secondaryFontFamily;
$secondaryCaption: $normalWeight 14px/1.43 $secondaryFontFamily;
$secondarySmall: $normalWeight 16px/1.75 $secondaryFontFamily;
$secondaryBody: $normalWeight 18px/1.78 $secondaryFontFamily;
$secondaryLead: $normalWeight 24px/1.5 $secondaryFontFamily;
$secondaryHeadline5: $normalWeight 21px/1.52 $secondaryFontFamily;
$secondaryHeadline4: $normalWeight 26px/1.54 $secondaryFontFamily;
$secondaryHeadline3: $normalWeight 31px/1.55 $secondaryFontFamily;
$secondaryHeadline2: $normalWeight 37px/1.41 $secondaryFontFamily;
$secondaryHeadline1: $normalWeight 48px/1.25 $secondaryFontFamily;

$dataP1: $normalWeight 18px/1.56 $dataFontFamily;
$dataP2: $normalWeight 16px/1.5 $dataFontFamily;
$dataP3: $normalWeight 14px/1.43 $dataFontFamily;
$dataP4: $normalWeight 12px/1.67 $dataFontFamily;

$dataL1: $secondaryBoldWeight 18px/1.33 $dataFontFamily;
$dataL2: $secondaryBoldWeight 16px/1.25 $dataFontFamily;
$dataL3: $secondaryBoldWeight 14px/1.14 $dataFontFamily;
$dataL4: $secondaryBoldWeight 12px/1.33 $dataFontFamily;


