@import "utilities/colors.scss";
@import "mixins.scss";
@import url('react-tooltip/dist/react-tooltip.css');
@import url('rc-slider/assets/index.css');

html {
  line-height: 1.15;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  font-weight: 500;
  line-height: 1.2;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

*,
::before,
::after {
  box-sizing: border-box;
}

/* Hide arrow button in input, Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

::-webkit-scrollbar {
  background-color: $sectionBackground;
  height: 12px;
  width: 12px;
}

::-webkit-scrollbar-corner {
  background-color: $sectionBackground;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: darken($sectionHighlight, 5%);

  &:hover {
    background-color: $sectionHighlight;
  }
}

::-webkit-scrollbar-track {
  border-radius: 8px;
  background-color: $scrollBarBackground;
}

//Page Container
.page-container {
  grid-template-areas:
    "gap gap summery gap3"
    "sidebar gap2 page gap3";

  .filters-actions {
    display: flex;
    justify-content: space-between; // Puts filters on the left, and actions on the right.
  }

  .page {
    grid-area: page;
    height: 100%;
    padding: 12px;
    background: $bgCanvas;
    border-radius: 8px;
    overflow: auto;
  }
}

// HACK(kentskinner): this is so the DatePicker dropdown appears OVER the floating modal dialog.
div[data-baseweb="popover"] {
  z-index: 1000 !important;
}
