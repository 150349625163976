/* Colours */
$white: #FFFFFF;
$gray50: #FCFCFC;
$gray100: #F5F5F5;
$gray200: #EEEEEE;
$gray300: #E0E0E0;
$gray400: #BDBDBD;
$gray500: #9E9E9E;
$gray600: #757575;
$gray700: #616161;
$gray800: #424242;
$gray900: #323232;
$gray950: #1F1F1F;
$black: #171717;

// TODO(kentskinner): this seems misnamed
$gray6: #f2f2f2;

$slate50: #E8E9EC;
$slate75: #D9DCE4;
$slate100: #BBC5D7;
$slate150: #99a2b2;
$slate200: #868E9F;
$slate300: #3B4351;
$slate400: #28303E;
$slate500: #242933;

$pink300: #F093EE;
$pink500: #E028DD;

$blue100: #D8DDFD;
$blue200: #B1BAFB;
$blue300: #8B98F9;
$blue400: #6475F7;
$blue500: #3D53F5;
$blue600: #3142C4;

$purple200: #BBB2EF;
$purple400: #7865E0;
$purple500: #563FD8;

/* Themes Start */

// Foreground
$fgDefault: $white;
$fgMuted: $gray600;
$fgSubtle: $gray700;
$fgOnAccent: #260626;
$fgOnDisabled: $gray500;
$fgLabelDefault: $slate200;

// Background
$bgSurface: $gray950;
$bgMuted: $gray800;
$bgSubtle: $gray900;
$bgCanvas: $black;
$bgDisabled: $gray800;
$bgApp: #282A2F; //this looks wrong, talk to Jesse

// Accent
$accentDefault: #E85EE5;
$accentEmphasis: $pink500;
$accentMuted: #731172;
$accentSubtle: #4D0B4C;
$accentDisabled: $gray800;

// Accent2
$accentSecDefault: $blue400;
$accentSecEmphasis: #3D53F5;
$accentSecMuted: #253293;
$accentSecSubtle: #182162;
$accentSecDisabled: $gray800;

// Warning
$warningDefault: #FFCF74;
$warningEmphasis: #FFBB38;
$warningMuted: #B38327;
$warningSubtle: #33250B;
$warningOnWarning: $black;

// Error
$errorDefault: #E95C7B;
$errorEmphasis: #DF1642;
$errorMuted: #9C0F2E;
$errorSubtle: #9C0F2E;
$errorOnError: $fgDefault;

// Info
$infoDefault: $blue600;
$infoEmphasis: #3D53F5;
$infoMuted: #8B98F9;
$infoSubtle: #D8DDFD;
$infoOnInfo: $fgDefault;

// Success
$successDefault: #5DC389;
$successEmphasis: #18A957;
$successMuted: #11763D;
$successSubtle: #052211;
$successOnSuccess: $fgDefault;

// Input
$inputBgDefault: $gray950;
$inputText: $gray300;
$inputPlaceHolder: $gray600;
$inputBorderDefault: $gray500;
$inputBorderDisabled: $gray800;
$inputFgDisabled: $gray700;

// Border
$borderDefault: $gray900;

/* Themes End */

// brand
$brandMainPink: #ea27c2;
$brandDarkBlue: #051c2c;

//labels
$darkPrimaryLabel: #e64f00;

// ui
$sectionBackground: $bgSurface;
$cardBackground: $black;
$sectionHighlight: #282a2b;
$deprecatedNavBorder: #383b40;
$deprecatedModalBackground: #464849;
$scrollBarBackground: lighten($sectionBackground, 5%);

//chart colors
$teal: #3CCECE;
$purple: #9B51E0;
$blue: #307DE3;
