@import "style/utilities/colors";
@import "style/utilities/variables";

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;

  &__container {
    min-width: 380px;
    width: 100%;
    height: 100%;
    background: $black;
    color: $white;
    z-index: 10;
    border-radius: 6px;
    box-shadow: 0px 16px 24px 0px #0d0d0de5;
    padding: 24px;
  }

  &__close {
    background: none;
    border: none;
    color: $white;
    float: right;
    font-size: $fsHeadline5;

    &:hover {
      background: #333333;
    }
  }

  &__header_container {
    text-align: left;
  }

  &__header_text {
    font-size: $fsLead;
    line-height: 24px;
    margin: 0 0 16px 0;
  }

  &__header_subtext {
    font-size: $fsSmall;
    font-weight: 400;
    margin-bottom: 8px;
    color: #828282;
    text-transform: uppercase;
  }

  &__header_caption {
    font-size: $fsXSmall;
    margin-bottom: 12px;
    color: #909199;
  }

  &__body {
    padding-bottom: 8px;
    padding: 0;
  }

  &__backdrop {
    position: absolute;
    top: 0;
    left: 0;
    background: #222429;
    opacity: 0.5;
    height: 100%;
    width: 100vw;
  }

  &__document_library {
    .modal {
      &__container {
        min-width: 700px;
        padding: 32px 80px;
        text-align: left;
      }

      &__header_text {
        font-size: $fsHeadline5;
        margin: 16px 0;
      }

      &__header_subtext {
        font-size: $fsBody;
        font-weight: 700;
        color: $white;
        text-transform: none;
      }

      &__header_caption {
        font-size: $fsBody;
        margin-bottom: 12px;
        color: $white;
      }
    }
  }
}
