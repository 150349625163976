.png-icon {
  &--small {
    height: 16px;
  }

  &--medium {
    height: 24px;
  }

  &--large {
    height: 32px;
  }

  &--extraLarge {
    height: 40px;
  }
}
