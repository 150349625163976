@import "style/utilities/colors";
@import "style/utilities/variables";

.c-button_group {
  display: flex;

  &--primary {
    & .c-button {
      background: $bgSurface;
      color: $accentDefault;
      border-radius: 0;
      border: 1px solid $bgSurface;
    }
  }

  &--secondary {
    & .c-button {
      background: $bgSurface;
      color: $accentDefault;
      border-radius: 0;
      border: 1px solid $accentDefault;
    }
  }

  & .c-button {
    &--selected {
      background: $accentDefault;
      color: $gray950;
    }

    &:first-of-type {
      border-radius: 4px 0 0 4px;
    }

    &:last-of-type {
      border-radius: 0px 4px 4px 0;
    }
  }

  &--small {
    & .c-button {
      font-size: $fsXSmall;
      line-height: 16px;
      padding: 6px 25px;
    }
  }

  &--medium {
    & .c-button {
      font-size: $fsSmall;
      line-height: 16px;
      padding: 6px 33px;
    }
  }

  &--large {
    & .c-button {
      font-size: $fsBody;
      line-height: 20px;
      padding: 8px 45px;
    }
  }
}
